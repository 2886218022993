<template>
  <div class="resultWrap">
    <div class="content">
      <h4>{{ title }}</h4>
      <p>
        <span>{{ result }}</span>
      </p>
      <div v-if="data.index == 0" class="tips">
        <dl>
          <dt>解读：</dt>
          <dd>
            ①系统判定结果为左眼或右眼抑制，单眼抑制导致弱视从而引起斜视。<br />
            ②系统判定结果为复视，是双眼融合机能障碍的表现，出现斜视的可能性较大。
          </dd>
        </dl>
        <dl>
          <dt>训练建议：</dt>
          <dd>
            ①左/右眼抑制：脱抑制训练+同时视训练<br />
            ②复视：融合训练+立体视训练
          </dd>
        </dl>
        <dl>
          <dt>声明：</dt>
          <dd>
            ①本检查结果仅供定性参考，不能作为临床诊断依据，如需获取更精准的报告，请到相关医疗机构就诊检查。<br />
            <!-- ②如有其他问题，请联系：18190721582 -->
          </dd>
        </dl>
      </div>

      <div v-else class="tips">
        <dl>
          <dt>解读：</dt>
          <dd>
            ①双眼同时能看到十字和圆圈则说明双眼同时视功能正常。<br />
            ②十字不能准确放进圆圈中心则说明眼位不正，发生视力不良的可能性会增大。<br />
            ③十字放在圆圈外侧且不相交，则出现隐斜视的可能性较大。<br />
          </dd>
        </dl>
        <dl>
          <dt>训练建议：</dt>
          <dd>同时视训练+融合训练</dd>
        </dl>
        <dl>
          <dt>声明：</dt>
          <dd>
            ①本检查结果仅供定性参考，不能作为临床诊断依据，如需获取更精准的报告，请到相关医疗机构就诊检查。<br />
            <!-- ②如有其他问题，请联系：18190721582 -->
          </dd>
        </dl>
      </div>
      <div class="button" @click="confirm"><span>确认</span></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "resultWrap",
  components: {},
  props: {},
  emits: [],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = route.query;
    const title = ref("抑制度检查结果");
    const result = ref("双眼正常");

    const init = () => {
      result.value = data.res;
      if (data.index == 0) {
        title.value = "抑制度检查结果";
      } else {
        title.value = "动态知觉眼位检查结果";
      }
    };

    const confirm = () => {
      router.push("/spreadIndex");
    };

    onMounted(() => {
      init();
    });
    return { title, result, data, confirm };
  },
});
</script>
<style lang="less" scoped>
.button {
  cursor: pointer;
  > span {
    width: 140px;
    height: 60px;
    text-align: center;
    letter-spacing: 4px;
    font-size: 30px;
    color: rgba(14, 104, 76, 1);
    line-height: 60px;
    background-color: #fff;
    border-radius: 5px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 27px;
}
.tips {
  dd {
    font-size: 26px;
  }
  dt {
    font-size: 32px;
  }
  dl {
    padding-bottom: 25px;
  }
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px dashed #ccc;
  padding: 25px 51px;
  color: rgba(26, 26, 26, 1);
}
.content {
  > p {
    > span {
      background-color: rgba(14, 104, 76, 1);
      color: #fff;
      font-size: 32px;
      font-weight: bold;
      padding: 23px 44px;
      line-height: 32px;
      border-radius: 5px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 28px;
  }
  > h4 {
    font-size: 44px;
    line-height: 44px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-align: center;
    padding-bottom: 63px;
  }
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px dashed #ccc;
  padding: 79px 115px 49px;
}
.resultWrap {
  background: url("~@/assets/images/bg.png") center/cover;
  min-height: 100vh;
  width: 100%;
  padding: 107px 75px;
  box-sizing: border-box;
}
</style>